const getBreadcrumbItemsFromBreadcrumbContents = (breadCrumbs)=>{
    return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadCrumbs.map((element, index)=>getBreadcrumbItem(index + 1, element.name, element.url))
    };
};
const getBreadcrumbItem = (position, name, url)=>{
    return {
        "@type": "ListItem",
        position: position,
        name: name,
        item: url
    };
};
const getVideoObject = (name, description, thumbnailUrl, embedUrl, uploadDate, duration)=>{
    return {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name: name,
        description: description,
        thumbnailUrl: thumbnailUrl,
        embedUrl: embedUrl,
        uploadDate: uploadDate,
        duration: duration
    };
};
const getNewsArticle = (headline, description, image, authorType, author, authorLogo, authorUrl, publisherType, publisher, publisherLogo, publisherUrl)=>{
    return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: headline,
        description: description,
        image: image,
        author: {
            "@type": authorType,
            name: author,
            logo: authorLogo,
            url: authorUrl
        },
        publisher: {
            "@type": publisherType,
            name: publisher,
            logo: publisherLogo,
            url: publisherUrl
        }
    };
};
export const getFAQPage = (name, questions)=>{
    return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        name: name,
        mainEntity: questions.map((question)=>getQuestion(question))
    };
};
const getQuestion = (question)=>{
    return {
        "@type": "Question",
        name: question.question,
        acceptedAnswer: {
            "@type": "Answer",
            text: question.answer
        }
    };
};
const getReview = (ratingValue, authorName, reviewBody)=>{
    return {
        "@type": "Review",
        reviewRating: {
            "@type": "Rating",
            ratingValue: `${ratingValue}`
        },
        author: {
            "@type": "Person",
            name: authorName
        },
        reviewBody: reviewBody
    };
};
const getAddress = (streetAddress, addressLocality, addressCountry, addressRegion, postalCode)=>{
    return {
        "@type": "PostalAddress",
        streetAddress: streetAddress,
        addressLocality: addressLocality,
        addressCountry: addressCountry,
        addressRegion: addressRegion,
        postalCode: postalCode
    };
};
const getOrganization = (name, alternateName, legalName, description, url, logo, email, sameAs, ratingValue, bestRating, reviews, address, minNumberOfEmployees, maxNumberOfEmployees, foundingDate)=>{
    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        url: url,
        logo: logo,
        name: name,
        alternateName: alternateName,
        legalName: legalName,
        description: description,
        email: email,
        sameAs: sameAs,
        address: address,
        numberOfEmployees: {
            "@type": "QuantitativeValue",
            minValue: minNumberOfEmployees,
            maxValue: maxNumberOfEmployees
        },
        aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: `${ratingValue}`,
            bestRating: `${bestRating}`,
            ratingCount: `${reviews.length}`
        },
        review: [
            reviews
        ],
        foundingDate: foundingDate
    };
};
const getWebsite = (name, url)=>{
    return {
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: name,
        url: url
    };
};
export { getBreadcrumbItemsFromBreadcrumbContents, getVideoObject, getNewsArticle, getQuestion, getOrganization, getAddress, getReview, getWebsite,  };
