export const subscriptionProductCategoriesSlug = [
    "subscription"
];
export const businessSubscriptionProductCategoriesSlug = [
    "business-subscription"
];
export const cloudHostingProductCategoriesSlug = [
    "cloud-hosting"
];
export const serverlessHostingProductCategoriesSlug = [
    "serverless-hosting"
];
export const selfHostingProductCategoriesSlug = [
    "self-hosting"
];
export const profileProductCategoriesTypes = [
    "profile-parent",
    "profile",
    "index"
];
export const pinescriptStrategyCategorySlug = "pinescript-strategy";
export const defaultSubscriptionProductSlug = "subscription-product-1";
export const defaultBusinessSubscriptionProductSlug = "subscription-product-business-2";
export const bestSubscriptionProductSlug = "subscription-product-2";
export const bestBusinessSubscriptionProductSlug = "subscription-product-business-2";
export const bestHostingProductSlug = "hosting-cloud-2";
export const tradingViewStrategyProductSlug = "tradingview-strategy";
export const automationProductSlugs = [
    tradingViewStrategyProductSlug
];
export const automationWelcomeProductSlugs = [
    tradingViewStrategyProductSlug
];
export const manualTradingEnabledProductSlugs = [
    "subscription-product-3",
    "subscription-product-4"
];
export const allSubscriptionProductSlugs = [
    "subscription-product-1",
    "subscription-product-2",
    "subscription-product-3",
    "subscription-product-4"
];
export const defaultAutomationSubscriptionSlug = "subscription-product-3";
export const allowedAutomationSubscriptionSlugs = [
    "subscription-product-3",
    "subscription-product-4"
];
export const allowedStrategyGenerationSubscriptionSlugs = [
    "subscription-product-3",
    "subscription-product-4"
];
export const strategiesSubcategoriesToDisplay = [
    "popular",
    "ai-dca",
    "classic-dca",
    "classic-grid",
    "all"
];
export const indexSubcategoriesToDisplay = [
    "popular",
    "trending",
    "top",
    "thematic",
    "all"
];
export const copyTradingSubcategoriesToDisplay = [
    "trader"
];
export const ProductRisks = [
    "High",
    "Moderate",
    "Low"
];
// name
export const ProductNameUpdateRegex = /[^a-zA-Z0-9\s]/g;
export const ProductNameMaxSize = 25;
export const ProductNameMinSize = 3;
export const productProfitabilityPeriods = [
    "7d",
    "1m",
    "6m"
];
export const productProfitabilityOverviewPeriods = [
    "1m"
];
export const getProfitabilityPeriodFallback = (period)=>{
    switch(period){
        case "7d":
            return "14d";
        case "14d":
            return "1m";
        case "1m":
            return "3m";
        case "6m":
            return "3m";
        case "1y":
            return "6m";
        default:
            return "1m";
    }
};
export const defaultProductPlanInterval = "monthly";
export const defaultProductPlanPriceDisplayInterval = "monthly";
export var ProductFeature;
(function(ProductFeature) {
    ProductFeature["SPOT_TRADING"] = "spot_trading";
    ProductFeature["FUTURES_TRADING"] = "futures_trading";
    ProductFeature["PAPER_TRADING"] = "paper_trading";
    ProductFeature["PAPER_TRADING_DURATION"] = "paper_trading_duration";
    ProductFeature["MAX_SIMULTANEOUS_BOTS"] = "max_simultaneous_bots";
    ProductFeature["STRATEGY_DESIGN"] = "strategy_design";
    ProductFeature["STRATEGY_BACKTESTER"] = "strategy_backtester";
    ProductFeature["INTRODUCTION_CALL"] = "introduction_call";
    ProductFeature["SIMPLE_STRATEGIES"] = "simple_strategies";
    ProductFeature["ADVANCED_STRATEGIES"] = "advanced_strategies";
    ProductFeature["DCA_BOT"] = "dca_bot";
    ProductFeature["GRID_BOT"] = "grid_bot";
    ProductFeature["PRO_BOT"] = "pro_bot";
    ProductFeature["TRIANGULAR_ARBITRAGE"] = "triangular_arbitrage";
    ProductFeature["AI_BOT"] = "ai_bot";
    ProductFeature["SIGNAL_BOT"] = "signal_bot";
    ProductFeature["TRADING_VIEW_BOT"] = "tradingview_bot";
    ProductFeature["ADVANCED_TRADING_VIEW_BOT"] = "advanced_tradingview_bot";
    ProductFeature["INDEX_BOT"] = "index_bot";
    ProductFeature["ADVANCED_INDEX_BOT"] = "advanced_index_bot";
    ProductFeature["CLOUD_TRADING"] = "cloud_trading";
    ProductFeature["TRADING_VIEW_INTEGRATION"] = "tradingview_integration";
    ProductFeature["CHATGPT_INTEGRATION"] = "chatgpt_integration";
    ProductFeature["MOBILE_APP"] = "mobile_app";
    ProductFeature["PORTFOLIO_MAX"] = "portfolio_max";
    ProductFeature["PORTFOLIO_OVERVIEW"] = "portfolio_overview";
    ProductFeature["PORTFOLIO_HISTORY"] = "portfolio_history";
    ProductFeature["TRADES_STATS"] = "trade_stats";
    ProductFeature["EMERGENCY_BUTTON"] = "emergency_button";
    ProductFeature["MANUAL_ORDERS"] = "manual_orders";
    ProductFeature["MANUAL_PORTFOLIO_REBALANCING"] = "manual_portfolio_rebalancing";
    ProductFeature["SERVER_POWER"] = "server_power";
    ProductFeature["SYMBOL_LIMIT"] = "symbol_limit";
    ProductFeature["EXCHANGE_LIMIT"] = "exchange_limit";
    ProductFeature["REGULAR_CALL"] = "regular_call";
    ProductFeature["BESPOKE_EMAIL_NEWS_RECAPS"] = "bespoke_email_news_recaps";
    ProductFeature["PRIORITY_SUPPORT"] = "priority_support";
    ProductFeature["CUSTOM_STRATEGY_WITH_AI"] = "custom_strategy_with_ai";
    ProductFeature["IMPORT_STRATEGY_WITH_AI"] = "import_strategy_with_ai";
    ProductFeature["CUSTOM_TRADING_SIGNALS"] = "custom_trading_signals";
})(ProductFeature || (ProductFeature = {}));
export const tradingTypes = [
    {
        name: "invest"
    },
    {
        name: "trade"
    },
    {
        name: "automate"
    },
    {
        name: "copy"
    }
];
/* Product creator */ export const productCreatorProductCategorySlug = "signal-copy-trading";
export const orderedProductCreatorTabSlugs = [
    //'summary',
    "products",
    "ai"
];
export const orderedProductCreatorAdminTabSlugs = [
    "dashboard",
    "members",
    "signals"
];
export const orderedProductStrategyTabSlugs = [
    "overview",
    "trading",
    "customize",
    "creator"
];
export const categorySlugsCustomizationEnabled = [
    "dca-strategy",
    "grid-strategy"
];
